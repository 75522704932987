import { Component, OnInit, HostListener, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/_services/cart.service';
import { StoreService } from '../../_services/store.service';
import { CategoryService } from '../../_services/category.service';
import { environment } from 'src/environments/environment';
import { EventsService } from 'src/app/_services/events.service';
import { DataStorageService } from 'src/app/_services/dataStorage.service';

interface generalBarProps {
  selectedButtonBorderBottom: value;
  barBorderBottom:            value;
  buttonTextColor:            value;
  buttonBackgroundColor:      value;
  selectedButtonTextColor:    value;
}

interface value {
  type:  string;
  value: string;
}

@Component({
  selector: 'app-general-bar',
  templateUrl: './general-bar.page.html',
  styleUrls: ['./general-bar.page.scss'],
})

export class GeneralBarPage implements OnInit {

  @Input() props = {} ;

  status = true;
  escCode = 'Escape';
  enterCode = 'Enter';
  
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() cartButtonStatus = true;

  menuStatus = false;
  currentSelected = '';

  categories = [{name:'hola', subCategories: [{name:'chao'}, {name: 'hola'}, {name:'pescao'}]},
                {name:'hola', subCategories: [{name:'chao'}, {name: 'hola'}, {name:'pescao'}]},
                {name:'hola', subCategories: [{name:'chao'}, {name: 'hola'}, {name:'pescao'}]},
                {name:'chao', subCategories: [{name:'chao'}, {name: 'hola'}, {name:'pescao'}]},
                {name:'hola', subCategories: []},
                {name:'hola', subCategories: []}];

  focus = false;
  searchQuery = '';

  logoUrl='';
  loadingLogo = false;
  loadingProps= false;
  loadingElements = false;
  optionVisibilityStatus = false;

  client;
  isLogged = false;
  cartLength = 0;
  generalBarProps:generalBarProps;


  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(event.code === this.escCode){
      this.closeOpenModals();
    }

    if((event.code === this.enterCode) && (this.focus && this.searchQuery !== '')){
      this.navigateToProductCategory();
      this.searchQuery = '';
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private cartService: CartService,
     private eventsService: EventsService, 
     private storeService: StoreService, 
     private router: Router, 
     private categoryService: CategoryService,
     private dataStorage: DataStorageService) {

    
      }

  calculateCartLenght(cartArray){
    let value = 0;
    for(let product of cartArray){
      value += product.quantity; 
    }
    return value
  }

  async ngOnInit() {
    this.loadingElements = true;
    this.cartLength = this.calculateCartLenght(this.cartService.getCartArray());

    const segment = this.cartService.getCartEmitter();
    segment.subscribe((status: any) => {
      this.cartLength = this.calculateCartLenght(this.cartService.getCartArray());
    });

    this.eventsService.getEventEmitter('optionVisibility').subscribe(value =>{
      this.optionVisibilityStatus = value;
      segment.subscribe((status: any) => {
        this.cartLength = this.calculateCartLenght(this.cartService.getCartArray());
      });
  
    })


    this.categoryService.listFullCategory().then((response) => {
      response.subscribe((categories) => {
        this.categories = categories.sort((a, b) => (a.name > b.name) ? 1 : -1);
        console.log('this.c', this.categories)
        console.log('this.c', categories)
        this.loadingElements = false;
      });        
    });
    //setting component logo.
    this.loadingLogo = true;
    this.storeService.getCommonConfigByEnterprise(environment.enterpriseId, 'logo').subscribe( (data)=>{
      if(data){
        this.logoUrl = data['props']['imgUrl'];
      }
      this.loadingLogo = false;
    }, err=>{
      this.loadingLogo = false;
    });

    this.eventsService.getEventEmitter('optionVisibility').subscribe(value =>{
      this.optionVisibilityStatus = value;
    })

    this.client = await this.dataStorage.get('client');
    if(this.client){
      this.isLogged = true;
    }

    this.eventsService.getEventEmitter('isLogged').subscribe(value=>{
      this.isLogged = value;
  
      if(this.isLogged){
        this.getClient();
      }    
    });
 
    //setting component props.
    this.loadingProps = true;
    this.storeService.getCommonConfigByEnterprise(environment.enterpriseId, 'generalBar').subscribe( (data)=> {
      if(data){
      
        this.generalBarProps=data['props'];

      }
      this.loadingProps = false;
    }, err=>{
      this.loadingProps = false;
    });

   
  }

  async getClient(){
    this.client = await this.dataStorage.get('client');
  }

  changeOptionVisibilityStatus(){
    this.optionVisibilityStatus = !this.optionVisibilityStatus;
    this.eventsService.emitEventValue('optionVisibility', this.optionVisibilityStatus);
  }

  changeBar() {
    this.status = this.cartService.getCartVisibility();
    this.cartService.emitCartStatus(!this.status);
  }

  closeOpenModals(){
    this.cartService.emitCartStatus(false);
  }

  navigateToStorefront(){
    this.router.navigate(['storefront']);
  }

  navigateToProductCategory(){
    this.router.navigate(['/product-category', 's'],{queryParams: {search: this.searchQuery.trim()}});
  }

  navigateToProductCategory2(category){
    this.router.navigate(['/product-category', 'c'],{queryParams: {category: category.alias}});
  }

  navigateToProductCategorySub(subCategory){
    this.router.navigate(['/product-category', 'sc'],{queryParams: {subCategory: subCategory._id}});
  }

  openCategories(){
    this.currentSelected = 'categories';
    this.menuStatus = !this.menuStatus;
    
    if(!this.menuStatus){
      this.currentSelected = '';
    }
  }

  navigateToSellers(){
    this.router.navigate(['sellers']);
  }

  onFocus(){
    this.focus = true;
  }

  onBlur(){
    this.focus = false;
  }

  onDestroy(){
    this.searchQuery = '';
  }

  getButtonsStyle(){

    if(this.generalBarProps){
      if(this.currentSelected ){
        return {
          'border-bottom': '2px solid '+this.generalBarProps['selectedButtonBorderBottom']['value'],
          'color': this.generalBarProps['selectedButtonTextColor']['value'],
          'background-color': this.generalBarProps['buttonBackgroundColor']['value'],
        }
        }else{
        return {
          'border-bottom': 'none', 
          'color': this.generalBarProps['buttonTextColor']['value'],
          'background-color': this.generalBarProps['buttonBackgroundColor']['value']
        } 
      }
    }

    if(!this.generalBarProps || this.generalBarProps ==undefined || this.generalBarProps || null){
      return {
        'border-bottom': '2px solid white',
        'color': 'black',
        'background-color': 'white',
      }
    }
       
  
    
  }
  getBarStyles(){
       if(this.generalBarProps ){
        return {'border-bottom': '2px solid '+this.generalBarProps['barBorderBottom']['value'],
 
         }
        }else{
            return {'border-bottom': '2px solid white',
  
          }
        }
  
    
  }

  /*
  '.option-menu-container{'
                "color": "red",
                "border-bottom": "solid 1px green",
                "border-top":"solid 2px red",
                "border-left": "solid 2px red",
                "border-right":"solid 2px red",
                '}'*/

 

}
