import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CookieService {
  constructor() {
  }
  getUuid(){
      const cookies = document.cookie.split(";");
      for(const cookie of cookies){
        if( cookie.includes(`uuid${environment.marketKey}`) ){
            return cookie.replace(`uuid${environment.marketKey}=`,'');
        }
      }
      return;
  }
};
